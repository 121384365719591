<template>
  <div v-if="user" class="user-infos">
    <atom-image
      v-if="user.avatar"
      :src="imageUrl(user.avatar)"
      variant="rounded sm"
    ></atom-image>
    <span v-else class="user-infos__avatar-placeholder">{{
      user.firstname.slice(0, 1) +
      user.lastname.slice(0, 1)
    }}</span>
    <div class="user-infos__content">
      <atom-link :to="{name:'ForumUserProfile', params:{userId: user.id}}" replace class="user-infos__name" variant="black md">{{ user.firstname +' '+ user.lastname }}</atom-link>
      <p class="user-infos__role">{{ user.job }}</p>
      <router-link :to="{ name: 'ForumPost', params: { postId: postId } }" v-if="postDate" class="user-infos__date">
          Publié {{ moment(postDate).fromNow() }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { inject, toRefs } from "vue";
import moment from "moment";

const props = defineProps({
  user:{
    type:Object,
    required:true
  },
  postDate:{
    type:String,
    required: false,
    default:null
  },
  postId:{
    type:String,
    required:false,
    default:null
  }
});
const {user} = toRefs(props);
const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
  .user-infos{
    @apply gap-3 flex;
    &__avatar{
      &-placeholder{
        @apply h-5 w-5 bg-primary text-white flex items-center justify-center p-4 rounded-full
          text-sm font-bold shadow-sm;
      }
    }
    &__role{
      @apply text-xs text-black;
    }
    &__date{
      @apply text-black/80 text-xs;
    }
    &__content{
      @apply flex flex-col gap-[4px];
    }
  }
</style>
