<template>
  <molecule-card-wrapper :class="`forum-post ${loading ? 'forum-post--loading' : ''}`">
    <div class="forum-post__header">
      <molecule-user-infos :user="post?.author" :postId="post.id" :postDate="post.createdAt" class="forum-post__header-user">
      </molecule-user-infos>
      <div v-click-outside="handleOutsideClick" class="forum-post__show-more" v-if="post.author.id == currentUser.userId || currentUser.role == 'admin'"> 
        <div class="flex gap-2 items-center">
          <atom-icon class="cursor-pointer" @click.prevent="isPostDropdownOpen = true" icon="more" :fill="true"/>
        </div>
        <div v-show="isPostDropdownOpen" class="forum-post__dropdown">
          <button class="forum-post__dropdown-btn" @click.prevent="emit('editPost', post)">
            <atom-icon class="forum-post__dropdown-btn-icon" icon="pencil"/>
            <span>Modifier</span>
          </button>
          <button class="forum-post__dropdown-btn" @click.prevent="isConfirmationModalOpen = true">
            <atom-icon class="forum-post__dropdown-btn-icon" icon="delete-bin-7"/>  
            <span>Supprimer</span>
          </button>
          <block-confirmation-modal @modalSubmit="deletePost($event)" @modalClose="isConfirmationModalOpen = false" :open="isConfirmationModalOpen" :content="confirmationModalContent"/>
        </div>
      </div>
    </div>
    <div class="forum-post__body">
      <p class="forum-post__body-summary">
        {{ postContent }}
      </p>
      <button class="forum-post__body-button" v-if="post?.content.length > 1000" @click.prevent="isExpanded = !isExpanded">
        {{ isExpanded ? 'Voir moins' :'Lire la suite' }}
      </button>
      <template v-if="post.files?.length">
        <div v-if="post.files[0].type == 'image'" :class="`forum-post__body-images forum-post__body-images--contain`">
          <Carousel :settings="settings">
            <Slide v-for="(image, index) in post.files" :key="index">
              <img
                class="forum-post__body-image"
                :src="imageUrl(image.path)"
                @click="router.push({ name: 'ForumPost', params: { postId: post.id } })"
              />
              <!-- <img
                class="forum-post__body-image forum-post__body-image--blurred"
                :src="imageUrl(image.path)"
              /> -->
            </Slide>
            <template #addons="{ slidesCount }">
              <Navigation />
              <Pagination v-if="slidesCount > 1"/>
            </template>
          </Carousel>
        </div>
        <div v-else class="forum-post__body-attachments">
          <div 
            v-for="(file, index) in post.files"
            :key="index"
            class="forum-post__body-attachment"
          >
            <atom-icon class="forum-post__body-attachment-icon" icon="attachment"/>
            <span class="forum-post__body-attachment-text">Attachement {{index + 1}}</span>
            <a :href="fileUrl(file.path)" target="_blank">
              <atom-icon class="forum-post__body-attachment-icon" icon="eye"/>
            </a>  
            <atom-icon @click.prevent="downloadFile(file.path, `attachement${index + 1}.pdf`)" class="forum-post__body-attachment-icon" icon="download"/>  
          </div>
        </div>
      </template>
    </div>
    <div class="forum-post__footer">
      <div class="forum-post__footer-item">
        <atom-icon
          class="forum-post__footer-item-icon"
          icon="question-answer"
        ></atom-icon>
        <router-link
          class="forum-post__footer-item-content"
          :to="{ name: 'ForumPost', params: { postId: post.id } }"
        >
          <span v-if="post.commentsCount == 0">Pas de commentaires</span>
          <span v-else>Commentaires <span class="text-primary font-bold">{{post.commentsCount}}</span></span>
        </router-link>
      </div>
      <div 
        @click="reactToPost('like')" 
        :class="`forum-post__footer-item ${isCurrentUserReaction('like') ? 'forum-post__footer-item--active' : ''}`"
      >
        <atom-icon
          class="forum-post__footer-item-icon"
          icon="thumb-up"
          :fill="isCurrentUserReaction('like')"
        ></atom-icon>
        <span class="forum-post__footer-item-content">{{post.reactions?.likes}}</span>
      </div>
      <div 
        @click="reactToPost('dislike')" 
        :class="`forum-post__footer-item ${isCurrentUserReaction('dislike') ? 'forum-post__footer-item--active' : ''}`"
      >
        <atom-icon
          class="forum-post__footer-item-icon"
          icon="thumb-down"
          :fill="isCurrentUserReaction('dislike')"
        ></atom-icon>
        <span class="forum-post__footer-item-content">{{post.reactions?.dislikes}}</span>
      </div>
    </div>
  </molecule-card-wrapper>
</template>

<script setup>
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import MoleculeUserInfos from "@/components/molecules/MoleculeUserInfos.vue";
import BlockConfirmationModal from "@/components/blocks/BlockConfirmationModal.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import { toRefs, ref, computed, inject } from "vue";
import {useAuth} from "@/composables/auth";
const {state} = useStore();
const {dispatch} = useStore();
const router = useRouter();
const {currentUser} = useAuth();
const props = defineProps({
  post: {
    type: Object,
    default: {},
  },
  imageMode:{
    type:String,
    default:"cover"
  },
  expand:{
    type:Boolean,
    default:false
  }
});
const isExpanded = ref(props.expand);
const handleOutsideClick = () => {
  isPostDropdownOpen.value = false;
};
const postContent = computed( () => {
  return isExpanded.value ? props.post.content : props.post.content.substring(0, 1000);
});
const emit = defineEmits(['editPost']);
const { post } = toRefs(props);
const loading = computed(() => state.posts.loading);
const settings = ref({
  itemsToShow: 1,
});
const isPostDropdownOpen = ref(false);
const imageUrl = inject('imageUrl');
const isConfirmationModalOpen = ref(false);
const confirmationModalContent = {
  title:'Êtes-vous sûr de vouloir supprimer cette publication ?',
  description:"En cliquant sur Confirmer, cette publication sera définitivement suppriméé et il n'y aura aucun moyen de la récupérer. Si ce n'était pas prévu, veuillez cliquer sur Annuler pour annuler la suppression.",
  primaryCta:'Confirmer'
}
const isCurrentUserReaction = (type) => {
  return post.value.reactions?.currentUserReaction == type;
}
const reactToPost =  async (type) =>  {

  // Create reaction
  if(!isCurrentUserReaction(type)){
    try {
      const isSuccess = await dispatch('posts/createReaction', {
        type,
        content:post.value.id
      }); 
    } catch (err) {
        const {message} = err.response.data;
        console.log(message);
    }
  }
  // Remove it
  else{
    try {
      const isSuccess = await dispatch('posts/deleteReaction', {
        type,
        content:post.value.id
      }); 
    } catch (err) {
        const {message} = err.response.data;
        console.log(message);
    }
  }
}
const fileUrl = (path) => {
  return process.env.VUE_APP_ASSETS_BASE + path;
}
const downloadFile = async (path, filename) => {
  try {
    const blob = await dispatch('files/download', fileUrl(path));
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  } catch (err) {
    console.log(err);
  }
}

const deletePost = async (isConfirmed) => {
  if(isConfirmed){
    try {
      const deletedPost = await dispatch('posts/deletePost', post.value.id);    
      router.push({name:'ForumHome'});
    } catch (err) {
      const {message} = err.response.data;
      console.log(message);
    }
  }
  isConfirmationModalOpen.value = false;
}

</script>

<style lang="scss">
.forum-post {
  @apply mb-3 lg:mb-5 shadow-sm;
  &__header {
    @apply flex justify-between mb-1;
    &-user {
    }
    &-date {
      @apply text-[9px] sm:text-xs;
    }
  }
  &__body {
    @apply mr-4;
    &-title {
      @apply text-base leading-5 inline-block
      font-bold mt-2
      lg:text-lg;
    }
    &-summary {
      @apply text-base text-black mt-[13px] mb-1 leading-6;
    }
    &-button{
      @apply mb-5 text-primary font-medium underline;
    }
    &-images {
      @apply flex flex-col gap-1 mb-6
      md:flex-row md:gap-[4px] cursor-pointer;
      .carousel{
        @apply flex-1;
      }
      &--contain{
        @apply bg-black rounded-md;
        .forum-post__body-image{
          @apply object-contain;
        }
      }
    }
    &-image {
      @apply rounded-md flex-1 md:w-0 h-[500px] object-cover;
      &--blurred{
        @apply absolute z-[-1] w-full object-cover blur-md brightness-90 bg-black;
        clip-path: inset(0% 0% 0% 0% round 6px);
      }
    }
    &-attachments {
      @apply flex flex-col gap-2 mb-6;
    }
    &-attachment {
      @apply cursor-pointer
      bg-white border border-gray-100 rounded-md shadow-sm
      p-2 
      flex items-center gap-1;
      &-text{
        @apply flex-1 font-medium text-gray-600 text-sm;
      }
      &-icon{
        @apply w-4 h-4 fill-[gray];
      }
    }
  }
  &__footer {
    @apply flex gap-13 mb-[3px];
    &-item {
      @apply flex items-center gap-1 cursor-pointer;
      &-icon {
        @apply fill-[#858585] w-4 h-4;
      }
      &-content {
        @apply text-[#858585] text-xs;
      }
      &--active{
        .forum-post__footer-item{
          &-icon{
            @apply fill-[#7DAF8C];
          }
          &-content{
            @apply text-primary font-bold;
          }
        }
      }
    }
  }
  &__show-more {
    @apply flex flex-col gap-2 relative;
  }
  &__dropdown {
    @apply bg-white border-gray-200 border z-10 absolute top-5 right-0 shadow-sm w-[150px] rounded-lg;
    &-btn{
      @apply w-full py-2 px-3 text-gray-base text-left flex gap-[4px] items-center;
      &:first-child{
        @apply border-b border-gray-200
      }
      &-icon{
        @apply w-5 h-5 fill-[#6A6A6A];
      }
    }
  }
  .carousel {
    &__icon {
      @apply fill-[black];
    }
    &__prev,
    &__next {
      @apply bg-gray-50 transition transition-all;
      &--in-active {
        @apply opacity-0;
      }
    }
    &__prev {
      @apply left-6;
    }
    &__next {
      @apply right-6;
    }
    &__pagination {
      @apply gap-2 relative my-1;
      &-button {
        @apply h-[12px] w-[12px] rounded-full;
        background-color: rgba(196, 196, 196, 0.6);
        &--active {
          @apply bg-primary;
        }
      }
    }
  }
    &--loading{
    }
  }
</style>
