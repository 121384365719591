<template>
  <div class="bg-white p-2 rounded-md">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
